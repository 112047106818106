import React, { useState } from "react";
import { Container, Row, Col, CarouselItem, Button, CardDeck, Card, CardTitle, CardText, CardImg } from "reactstrap";

import Layout from "../components/layout";
import SEO from "../components/seo";

import banner from "../images/support-01.png";

import support1 from "../images/small icons-07.png";
import support2 from "../images/small icons-08.png";
import support3 from "../images/small icons-09.png";
import { Link } from "gatsby";

const supportItems = [
    {
        src: support1,
        title: "Personalised Service",
        description: "You benefit from the expert services of our professional team through a dedicated Account Manager.",
    },
    {
        src: support2,
        title: "Free Training & Resources",
        description: "Free Training & Resources through our Drive Revenue Learning Centre.",
    },
    {
        src: support3,
        title: "Expert Support Team",
        description: "Access to our Expert Support Team, from anywhere in the world, at any time.",
    },
];

const Support = () => {
    const supportCards = supportItems.map(x =>
        <Card key={x.title} body style={{ background: "linear-gradient(to bottom,#1C94FC ,#3750C2 100%)" }}>
            <CardImg src={x.src} className="p-5" />
            <CardTitle tag="h3" className="text-white text-center pt-4 pb-3">
                {x.title}
            </CardTitle>
            <CardText className="text-center text-white d-flex flex-column align-items-center pb-4">
                {x.description}
            </CardText>
        </Card>
    );

    return (
        <Layout>
            <SEO title="Support" />

            <Container fluid>
                <Row>
                    <Col className="top-carousel pl-0 pr-0">
                        <CarouselItem
                            className="d-flex align-items-center top-carousel"
                        >
                            <div className="carousel-caption d-none d-md-block position-relative text-left mb-4 pb-4" style={{ left: "10%" }}>
                                <h1 className="display-3">
                                    24/7 Support no matter where you are in the world.
                                </h1>
                                <p className="pt-4 pb-4 mt-4 mb-4 h5">
                                    A dedicated team of account managers are ready attend to your technical and training needs.
                                </p>
                                <Button outline color="light text-uppercase mb-4 rounded-pill pr-5 pl-5 pt-3 pb-3" size="lg" tag={Link} to="/contact-us">
                                    Contact our team
                                </Button>
                            </div>

                            <img src={banner} style={{ height: "70vh" }} className="" />

                        </CarouselItem>
                    </Col>
                </Row>
            </Container>

            <Container className="pt-5 mt-5 mb-5 pb-5">
                <Row className="pt-5">
                    <Col>
                        <h1 className="display-3 text-center text-primary mb-5">
                            We Are Here Every Step<br /> of The Way
                        </h1>
                        <p className="lead pt-4 text-center">
                            Helping You Take Your Practice to The Next Level
                        </p>
                    </Col>
                </Row>
                <Row className="pt-5">
                    <Col>
                        <CardDeck>
                            {supportCards}
                        </CardDeck>
                    </Col>
                </Row>
            </Container>

            <Container fluid style={{ background: "#0F0F34", marginTop: -500, paddingTop: 600 }}>
                <Row>
                    <Col>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={9} className="mb-5">
                                    <h1 className="h1 text-white text-center">
                                        To get in touch with our team, please contact us below and we will get back to you!
                                    </h1>
                                    <p className="text-center pt-5 pb-5 mb-5">
                                        <Button className="text-uppercase rounded-pill p-2 pl-4 pr-4" color="primary" size="lg" tag={Link} to="/contact-us">
                                            Get support
                                        </Button>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

        </Layout>
    );
};

export default Support;
